<script>
import moment from 'moment'
import './Subscription.styl'
import '@/views/dashboard/flyer/Payment/Payment.styl'
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import Spinner from '@/components/common/Spinner/Spinner.vue';
import {subscriptionStatusConstants} from '@/js/constants/subscriptionStatusConstants'
import {mapGetters} from 'vuex';

export default {
  name: 'Subscription',
  components: {
    Spinner,
    SvgIcon,
  },
  props: {
    subscription: Object,
    openWithEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    notificationTextFromPage: {
     type: String,
     required: false,
     default: '',
    },
  },
  computed: {
    ...mapGetters([
      'getEnrichedSubscriptionsPlans',
      'getInactiveUserSubscriptions',
      'getPaymentMethods',
    ]),
  },
  data () {
    return {
      card: '',
      number: '',
      cvv: '',
      expiryMonth: '',
      expiryYear: '',
      typeCard: 'credit',
      loadingCard: false,
      loading: false,
      addCardFormVisible: false,
      isShowEditing: false,
      selectedPlan: this.subscription.plan,
      selectedPaymentMethod: null,
      notificationText: '',
      sorryText: 'Something went wrong. We apologize. If the problem persists, please email us at FLY@flyjets.com',
    }
  },
  mounted () {
    this.isShowEditing = this.$props.openWithEdit
    this.notificationText = this.$props.notificationTextFromPage

    this.selectedPaymentMethod = this.getPaymentMethods.find(p => p.id === this.subscription.activationMethod?.paymentMethodId)
  },
  methods: {
    setNotificationOnPage (notificationTextFromPage, openWithEdit) {
      this.$emit('showNotification', {
        notificationTextFromPage,
        openWithEdit,
      })
    },

    clearNotificationText () {
      this.notificationText = ''
      this.setNotificationOnPage('', false)
    },

    fillNotificationAfterOpen () {
      if (this.subscription.isAppleSubscription) {
        this.notificationText = 'If you have chosen to manage your subscriptions via Apple, you will not be able to manage them through this platform.'
        return
      }

      const notificationsByStatus = {
        [subscriptionStatusConstants.waitingForActivation.code]: 'You cannot make any changes until the activation has been completed.',
        [subscriptionStatusConstants.activation.code]: 'You cannot make any changes until the activation has been completed.',
        [subscriptionStatusConstants.canceled.code]: 'You are not able to make changes while your subscription has been cancelled.',
      };

      this.notificationText = notificationsByStatus[this.subscription.status.code] || '';
    },

    getDisplayPaymentMethod (item) {
      return `${item.creditCardBrand} ************${item.creditCardLast4} ${item.creditCardExpMonth}/${item.creditCardExYear}`
    },
    getPlans () {
      return this.getEnrichedSubscriptionsPlans.filter(p =>
        p.id === this.subscription.plan.id ||
        (p.type.id === this.subscription.plan.type.id && p.isActive))
    },

    getStatusAndEndDate () {
      const statusLabels = {
        [subscriptionStatusConstants.active.code]: 'Renews',
        [subscriptionStatusConstants.expired.code]: 'Expired',
        [subscriptionStatusConstants.waitingForActivation.code]: 'Waiting for activation',
        [subscriptionStatusConstants.activationFailed.code]: 'Activation failed',
        [subscriptionStatusConstants.activation.code]: 'Activation in progress',
        [subscriptionStatusConstants.new.code]: 'Activation required',
        [subscriptionStatusConstants.abandoned.code]: 'Abandoned',
      };

      const type = statusLabels[this.subscription.status.code] || 'Expires on';

      if (type !== 'Renews' && type !== 'Expired' && type !== 'Expires on') {
        return type;
      }

      return `${type} ${moment(this.subscription.endDate).format('D MMMM YYYY')}`;
    },

    getVisibleTypeButton () {
      const noButtonStatuses = [
        subscriptionStatusConstants.waitingForActivation.code,
        subscriptionStatusConstants.activation.code,
      ];

      if (noButtonStatuses.includes(this.subscription.status.code) || this.subscription.isAppleSubscription) {
        return '';
      }

      if (this.getIsNextPlan(this.selectedPlan)) {
        return 'cancel';
      }

      if (this.selectedPlan.id !== this.subscription.planId) {
        return 'update';
      }

      const renewalStatuses = [
        subscriptionStatusConstants.new.code,
        subscriptionStatusConstants.activationFailed.code,
        subscriptionStatusConstants.expired.code,
        subscriptionStatusConstants.canceled.code,
      ];
      if (renewalStatuses.includes(this.subscription.status.code)) {
        return 'renew';
      }

      return 'cancel';
    },

    getDisableChoosePlan () {
      if (this.subscription.isAppleSubscription) {
        return true
      }

      const disabledStatusCodes = [
        subscriptionStatusConstants.canceled.code,
        subscriptionStatusConstants.waitingForActivation.code,
        subscriptionStatusConstants.activation.code,
      ];

      return disabledStatusCodes.includes(this.subscription.status.code);
    },

    changeSelectedPlan (item) {
      if (item.id === this.subscription.planId) {
        this.notificationText = ''
      } else if (item.tier > this.subscription.plan.tier) {
        this.showNotification('If you upgrade your plan, payment will be processed immediately, and the new plan will take effect right away.')
      } else if (item.tier <= this.subscription.plan.tier) {
        this.showNotification('If you upgrade your plan, your new subscription will begin when your current subscription expires on.')
      }
    },

    getIsCheckedPlan (item) {
      return item.id === this.subscription.plan.id
    },

    getIsCurrentPlan (item) {
      return item.id === this.subscription.plan.id
    },

    getIsNextPlan (item) {
      if (this.subscription.status.code !== subscriptionStatusConstants.active.code &&
        this.subscription.status.code !== subscriptionStatusConstants.canceled.code) {
        return false
      }

      const nextSubscription = this.getNextDowngradedSubscription()

      return item.id === nextSubscription?.planId
    },

    getNextDowngradedSubscription () {
      return this.getInactiveUserSubscriptions
      .filter(s => s.plan.type.code === this.subscription.plan.type.code && s.status.code === subscriptionStatusConstants.new.code)
      .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
      .shift()
    },

    getIsOutdatedPlan (item) {
      return !item.isActive
    },

    getDisplayValuePlan (item) {
      return `${item.name} ${item.duration} ${item.period.name} subscription (recurring) - $${item.price}/${item.period.name.toLowerCase()}`
    },
    async upgradeForCurrentAndNext () {
      if (this.getIsNextPlan(this.selectedPlan)) {
        this.showNotification('Your next plan is selected. Please, select new one');
      } else {
        const nextSubscription = this.getNextDowngradedSubscription();
        if (nextSubscription) {
          const subscriptionId = nextSubscription.id
          await this.executeIntegration(async () => {
            await this.$store.dispatch('cancelSubscriptionAndFetch', subscriptionId);
          });

          this.selectedPlan = this.subscription.plan;
        } else {
          this.showNotification('Your current plan is selected. Please, select new one');
        }
      }
    },
    async cancel () {
      try {
        const subscriptionId = this.subscription.id

        await this.executeIntegration(async () => {
          await this.$store.dispatch('cancelSubscriptionAndFetch', subscriptionId);
        });

        this.selectedPlan = this.subscription.plan;

        this.showNotification('We have cancelled your subscription, effective for the next billing cycle.');
      } catch (error) {
        this.showNotification(this.sorryText);
        console.error('Error canceling subscription:', error);
      }
    },

    async renew () {
      try {
        switch (this.subscription.status.code) {
          case subscriptionStatusConstants.expired.code:
            await this.createAndActivateNewSubscription()
            break;
          case subscriptionStatusConstants.new.code:
          case subscriptionStatusConstants.activationFailed.code:
            await this.activate();
            break;
          case subscriptionStatusConstants.canceled.code:
            await this.renewCanceledSubscription();
            break;
          default:
            this.showNotification(this.sorryText);
            console.error('Error renewing subscription: the subscription has an incorrect status for renew.');
        }
      } catch (e) {
        console.error('Error renewing subscription:', e);
        this.showNotification(this.sorryText);
      }
    },

    async createAndActivateNewSubscription () {
      if (!this.subscription.activationMethod?.paymentMethodId) {
        this.showNotification('Please specify the subscription payment method.');
        return;
      }

      await this.executeIntegration(async () => {
        try {
          const newSubscription = await this.$store.dispatch('createSubscription', {
            planId: this.selectedPlan.id,
          })

          await this.$store.dispatch('setSubscriptionPaymentMethod', {
            subscriptionId: newSubscription.id,
            paymentMethodId: this.selectedPaymentMethod.id,
          });

          await this.$store.dispatch('activateSubscription', {
            subscriptionId: newSubscription.id,
          });

          const statusCode = await this.checkActiveStatus(newSubscription.id, false);

          const statusMessages = {
            [subscriptionStatusConstants.activationFailed.code]: 'Unable to activate for subscription.',
            [subscriptionStatusConstants.waitingForActivation.code]: 'The subscription activation request has been submitted, but has not yet been processed. We will process the request as soon as possible.',
            [subscriptionStatusConstants.active.code]: 'Your subscription has been successfully activated. Your new subscription will start now.',
          };

          this.setNotificationOnPage(statusMessages[statusCode] || this.sorryText, true)
        } finally {
          await this.$store.dispatch('fetchSubscriptionsAndUpdateInUser')
        }
      })
    },

    async activate () {
      if (!this.subscription.activationMethod?.paymentMethodId) {
        this.showNotification('Please specify the subscription payment method.');
        return;
      }

      const payload = {
        subscriptionId: this.subscription.id,
      };

      await this.executeIntegration(async () => {
        await this.$store.dispatch('activateSubscriptionAndFetch', payload);

        const statusCode = await this.checkActiveStatus(this.subscription.id);

        const statusMessages = {
          [subscriptionStatusConstants.activationFailed.code]: 'Unable to activate for subscription.',
          [subscriptionStatusConstants.waitingForActivation.code]: 'The subscription activation request has been submitted, but has not yet been processed. We will process the request as soon as possible.',
          [subscriptionStatusConstants.active.code]: 'Your subscription has been successfully activated. Your new subscription will start now.',
        };

        this.showNotification(statusMessages[statusCode] || this.sorryText);
      });
    },

    async renewCanceledSubscription () {
      try {
        await this.executeIntegration(async () => {
          await this.$store.dispatch('renewSubscriptionAndFetch', this.subscription.id);
        });

        this.showNotification('Your subscription has been renewed.');
      } catch (e) {
        console.error('Error renewing subscription:', e);
        this.showNotification(this.sorryText)
      }
    },

    async update () {
      try {
        if (!this.subscription.activationMethod?.paymentMethodId) {
          this.showNotification('Please specify the subscription payment method.')
          return
        }

        if (this.subscription.status.code === subscriptionStatusConstants.new.code ||
          this.subscription.status.code === subscriptionStatusConstants.expired.code) {
          await this.createAndActivateNewSubscription()
        } else {
          await this.executeIntegration(async () => {
            const subscription = await this.$store.dispatch('upgradeSubscriptionAndFetch', {
              subscriptionId: this.subscription.id,
              planId: this.selectedPlan.id,
            });

            await this.postPlanUpdateActions(subscription.id);
          });
        }
      } catch (error) {
        console.error('Error updating subscription:', error);
        this.showNotification(this.sorryText);
      }
    },

    async postPlanUpdateActions (subscriptionId) {
      try {
        if (this.selectedPlan.tier > this.subscription.plan.tier) {
          const statusCode = await this.checkActiveStatus(subscriptionId, false);

          if (statusCode === subscriptionStatusConstants.active.code) {
            this.setNotificationOnPage('Your subscription has been successfully updated. Your new subscription will start now.', true)
          } else if (this.subscription.status.code === subscriptionStatusConstants.waitingForActivation.code) {
            this.showNotification('A request for a subscription change has been submitted, but payment has not been processed yet. Payment will be processed at a later date. For the time being, your current subscription remains active.')
          } else if (this.subscription.status.code === subscriptionStatusConstants.activationFailed.code) {
            this.showNotification('Unable to pay for subscription.')
          } else {
            this.showNotification(this.sorryText)
          }
        } else {
          this.showNotification(`Your subscription has been successfully updated.
          Your new subscription will begin when your current subscription expires on
          ${moment(this.subscription.endDate).format('D MMMM YYYY')}.`, true);
        }
      } finally {
        await this.$store.dispatch('fetchSubscriptionsAndUpdateInUser')
      }
    },

    async checkActiveStatus (subscriptionId, withUpdateSubscriptions = true) {
      let statusCode = ''

      let resolvePromise

      const promise = new Promise(resolve => {
        resolvePromise = resolve
      });

      const intervalId = setInterval(async () => {
        try {
          const subscriptions = await this.$store.dispatch('fetchSubscriptions')
          const subscription = subscriptions.find(s => s.id === subscriptionId)

          if (subscription.status.code === subscriptionStatusConstants.activationFailed.code) {
            statusCode = subscriptionStatusConstants.activationFailed.code
            clearInterval(intervalId)
            resolvePromise()
          }
          if (subscription.status.code === subscriptionStatusConstants.waitingForActivation.code ||
            subscription.status.code === subscriptionStatusConstants.activation.code) {
            statusCode = subscriptionStatusConstants.waitingForActivation.code
          }
          if (subscription.status.code === subscriptionStatusConstants.active.code) {
            statusCode = subscriptionStatusConstants.active.code
            clearInterval(intervalId)
            resolvePromise()
          }
        } finally {
          if (withUpdateSubscriptions) {
            await this.$store.dispatch('fetchSubscriptionsAndUpdateInUser')
          }
        }
      }, 1000)

      setTimeout(() => {
        clearInterval(intervalId)
        resolvePromise()
      }, 10000)

      await promise;

      return statusCode
    },

    getIsCheckedPaymentMethod (item) {
      return item.id === this.subscription.activationMethod?.paymentMethodId
    },

    getIsMainPaymentMethod (item) {
      return item.id === this.subscription.activationMethod?.paymentMethodId
    },

    getVisibleChangePaymentMethodButton () {
      if (this.subscription.isAppleSubscription) {
        return false
      }

      return this.selectedPaymentMethod?.id !== this.subscription.activationMethod?.paymentMethodId &&
      this.subscription.status.code !== subscriptionStatusConstants.waitingForActivation.code
    },

    getDisableChangePaymentMethod () {
      if (this.subscription.isAppleSubscription) {
        return true
      }

      return this.subscription.status.code === subscriptionStatusConstants.waitingForActivation.code ||
        this.subscription.status.code === subscriptionStatusConstants.activation.code
    },

    async changePaymentMethod () {
      try {
        await this.executeIntegration(async () => {
          const payload = {
            subscriptionId: this.subscription.id,
            paymentMethodId: this.selectedPaymentMethod.id,
          }
          await this.$store.dispatch('setSubscriptionPaymentMethodAndFetch', payload)
        })

        this.showNotification('The payment method has been successfully changed.')
      } catch (e) {
        this.showNotification(this.sorryText)
        console.error('Error changing payment method:', e);
      }
    },

    async executeIntegration (callback) {
      this.loading = true;

      try {
        await callback()
      } finally {
        this.loading = false;
      }
    },

    edit () {
      this.fillNotificationAfterOpen()
      this.isShowEditing = true
    },

    back () {
      this.isShowEditing = false
      this.selectedPlan = this.subscription.plan
    },

    showNotification (text) {
      this.notificationText = text
    },
    async submitHandlerCard () {
        const data = {
          number: this.number,
          expiryMonth: this.expiryMonth,
          expiryYear: this.expiryYear,
          cvv: this.cvv,
          usedFor: 1,
        }

        this.loadingCard = true

        const result = await this.$store.dispatch('addCard', data)

        let message = {}

        if (result === true) {
          message = {
            title: 'Card Added',
            text: 'Your card has been added successfully',
          }
        } else {
          message = {
            title: 'Something went wrong!',
            text: 'We apologize. If the problem persists, please email us at FLY@flyjets.com',
          }
        }
        this.$modal.show('ThanksPopup', {message})
        const response = await this.$store.dispatch('getPaymethods', true)
        this.allCards = response;
        this.number = ''
        this.expiryMonth = ''
        this.expiryYear = ''
        this.cvv = ''
        document.querySelectorAll('.js-acc-content').forEach((content) => {
            this.$root.animations.slideUp(content)
            this.addCardFormVisible = !this.addCardFormVisible
        })

        this.loadingCard = false
    },
    togglePayment (event) {
        this.addCardFormVisible = !this.addCardFormVisible
        const item = event.currentTarget.closest('.subscription-edits-container-inner__lower').querySelector('.js-acc-content')
        document.querySelectorAll('.js-acc-content').forEach((content) => {
          if (item !== content) {
            this.$root.animations.slideUp(content)
          }
        })
        this.$root.animations.slideToggle(item)
      },
  },
}
</script>

<template>
  <div>
    <div class="subscription">
      <div v-if="!isShowEditing" class="subscription-container">
        <div class="subscription-content">
          <div class="subscription-description__type">{{ subscription.plan.name}}</div>
          <div class="subscription-description">{{ `${subscription.plan.price} ${subscription.plan.currency.code}` }}</div>
          <div class="subscription-description">{{ getStatusAndEndDate() }}</div>
        </div>
        <div class="subscription-buttons">
          <button class="subscription-button" @click="edit">
            Edit
          </button>
        </div>
      </div>
      <div v-else>
        <div v-if="notificationText" class="notification">
          <span class="notification-text">{{ notificationText }}</span>
          <button class="notification-button" @click="clearNotificationText">&times;</button>
        </div>
        <div class="subscription-edits-content">
          <button class="button-left-arrow" @click="back">
            <span>
              <SvgIcon name="left-arrow"/>
            </span>
          </button>
          <div class="subscription-edits-container">
            <div class="subscription-edits-container-inner">
              <h2 class="edit">PLANS</h2>
              <div v-for="item in getPlans()" :key="item.id" class="plan-radio-group" :disabled="getDisableChoosePlan()">
                <input
                  :id="'plan-input-'+ item.id"
                  v-model="selectedPlan"
                  type="radio"
                  name="plan"
                  class="radio-input"
                  :value="item"
                  :checked="getIsCheckedPlan(item)"
                  :disabled="getDisableChoosePlan()"
                  @change="changeSelectedPlan(item)"
                >
                <label :for="'plan-input-'+item.id" :disabled="getDisableChoosePlan()" class="radio-label">{{getDisplayValuePlan(item)}}</label>
                <label :for="'plan-input-'+item.id" :disabled="getDisableChoosePlan()" class="radio-label__description" v-if="getIsCurrentPlan(item)">Current</label>
                <label :for="'plan-input-'+item.id" :disabled="getDisableChoosePlan()" class="radio-label__description" v-if="getIsNextPlan(item)">Next</label>
                <label :for="'plan-input-'+item.id" :disabled="getDisableChoosePlan()" class="radio-label__description" v-if="getIsOutdatedPlan(item)">Outdated</label>
              </div>
              <div class="subscription-buttons-edits">
                <button v-if="getVisibleTypeButton() === 'update'" class="subscription-button" @click="update">Update</button>
                <button v-if="getVisibleTypeButton() === 'cancel'" class="subscription-button" @click="upgradeForCurrentAndNext">Update</button>
                <button v-if="getVisibleTypeButton() === 'renew'" class="subscription-button" @click="renew">Renew</button>
              </div>
            </div>

            <div class="subscription-edits-container-inner__lower">
              <div class="payment-method">
                <h2 class="edit">PAYMENT METHODS</h2>
                <span v-if="!addCardFormVisible" @click="togglePayment"><SvgIcon class="add-header-icon-plus" name="add"/></span>
                <span v-if="addCardFormVisible" @click="togglePayment"><SvgIcon class="add-header-icon-plus" name="minus"/></span>
              </div>
              <div class="paymentDashboard__add-content js-acc-content" style="margin-top: 0">
                <div class="paymentDashboard__form">
                  <form
                    class="form form--addNewCardMethod js-addNewCardMethod"
                    :class="[{'loading' : loadingCard}]"
                    @submit.prevent="submitHandlerCard"
                  >
                    <div class="form__textGroup" style="padding-top: 0">
                      <label
                        class="form__label form__label--text">
                        <div class="form__caption">Card number:</div>
                        <div class="form__field-wrapper">
                          <input
                            class="form__field js-numberOnly"
                            type="text"
                            v-model="number"
                            data-mask="0000-0000-0000-0000"
                            v-mask="'####-####-####-####'"
                            minlength="16"
                            autocomplete="off"
                            placeholder=""
                            :disabled="loadingCard"
                          />
                        </div>
                      </label>
                      <label class="form__label form__label--code">
                        <div class="form__caption">Exp Month:</div>
                        <div class="form__field-wrapper">
                          <input
                            class="form__field js-gmi-datepicker"
                            type="text"
                            v-model="expiryMonth"
                            v-mask="'##'"
                            autocomplete="off"
                            :disabled="loadingCard"
                          />
                        </div>
                      </label>
                      <label class="form__label form__label--code">
                        <div class="form__caption">Exp Year:</div>
                        <div class="form__field-wrapper">
                          <input
                            class="form__field"
                            type="text"
                            v-model="expiryYear"
                            v-mask="'####'"
                            :disabled="loadingCard"
                          />
                        </div>
                      </label>
                      <label class="form__label form__label--code">
                        <div class="form__caption">CVV:</div>
                        <div class="form__field-wrapper">
                          <input
                            class="form__field js-numberOnly"
                            type="text"
                            v-model="cvv"
                            data-mask="000"
                            minlength="3"
                            autocomplete="off"
                            :disabled="loadingCard"
                          />
                        </div>
                      </label>
                      <div class="form__label form__label--button" style="margin-bottom: 0">
                        <Spinner/>
                        <button class="form__button" :disabled="loadingCard">
                          <span class="text">Save</span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div v-for="item in getPaymentMethods" :key="item.id" class="plan-radio-group">
                <input
                  :id="'payment-method-'+ item.id"
                  v-model="selectedPaymentMethod"
                  type="radio"
                  name="payment-method"
                  class="radio-input"
                  :value="item"
                  :checked="getIsCheckedPaymentMethod(item)"
                  :disabled="getDisableChangePaymentMethod()"
                >
                <label :for="'payment-method-'+item.id" :disabled="getDisableChangePaymentMethod()" class="radio-label">{{ getDisplayPaymentMethod(item) }}</label>
                <label :for="'payment-method--'+item.id" :disabled="getDisableChangePaymentMethod()" class="radio-label__description" v-if="getIsMainPaymentMethod(item)">main</label>
              </div>
              <div class="subscription-buttons-edits">
                <button v-if="getVisibleChangePaymentMethodButton()" class="subscription-button" @click="changePaymentMethod">change</button>
              </div>
            </div>
            <div v-if="!getDisableChoosePlan()" class="subscription-cancel">
              <span @click="cancel">Delete current subscription and continue to use FLYJETS Free</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading" class="spinner-overlay">
      <Spinner/>
    </div>
  </div>
</template>
